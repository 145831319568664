import { Navigate, useLocation } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";

// helpers
import { APICore } from "../helpers/api/apiCore";
import { APIRequest } from "../helpers";

// hooks
import { useUser } from "../hooks";
import { getBaseUrl } from "../helpers/api/utils";
import Loader from "../components/Loader";

type PrivateRouteProps = {
  component: React.ComponentType;
  roles?: string;
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({
  component: RouteComponent,
  roles,
  ...rest
}: PrivateRouteProps) => {
  let location = useLocation();
  const [loggedInUser] = useUser();
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [loaderState, setLoaderState] = useState<boolean>(false);
  const api = new APICore();

  
  useEffect(() => {
    getBaseUrl();
    // if (getBaseUrl()) {
    //   if (!localStorage.getItem("token")) {
    //     setLoaderState(true);
    //     // debugger
    //     fetch(`${localStorage.getItem("reqUrl")}/api/public/pre-login`, {
    //       method: "GET",
    //     })
    //       .then((response) => response.json())
    //       .then((data) => {
    //         if (data.status === "success") {
    //           setRedirectUrl(data.data["redirect-url"]);
    //         } else if (data.status === "unauthorized") {
    //           setRedirectUrl(data.data["redirect-url"]);
    //         }
    //       })
    //       .catch((error) => {
    //         console.error("Error:", error);
    //       });
    //   }
    // }
    // }, [localStorage.getItem("reqUrl")]);
  }, []);

  // useEffect(() => {
  //   if (redirectUrl) {
  //     window.location.href = redirectUrl;
  //   }
  // }, [redirectUrl]);

  // return loaderState ? <Loader /> : <RouteComponent {...rest} />;
  return <RouteComponent {...rest} />;
};

export default PrivateRoute;

// @ts-nocheck
import React, { useEffect, useState } from "react";
import _ from "underscore";
import { useLocation, redirect } from "react-router-dom";

type DownloadFile = {
  data: any;
  filename: string;
  mime: any;
  bom: any;
};

const downloadFile = ({ data, filename, mime, bom }: DownloadFile) => {
  var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  var blob = new Blob(blobData, { type: mime || "application/octet-stream" });

  var blobURL =
    window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(blob)
      : window.webkitURL.createObjectURL(blob);
  var tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobURL;
  tempLink.setAttribute("download", filename);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === "undefined") {
    tempLink.setAttribute("target", "_blank");
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  // Fixes "webkit blob resource error 1"
  setTimeout(function () {
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }, 200);
};

const TruncateText = (
  truncateText: string | undefined,
  stringLength: number
): string => {
  if (!truncateText) {
    return ""; // Return an empty string if truncateText is undefined or null
  }

  return truncateText.length > stringLength
    ? truncateText.substr(0, stringLength) + "..."
    : truncateText;
};

const transformArrayIntoTree = (inputArray: any) => {
  return inputArray.map((item: any) => ({
    value: item.hd_id,
    title: item.hd_name,
    children:
      item.children.length > 0 ? transformArrayIntoTree(item.children) : [],
  }));
};

const transformArrayIntoTreeFav = (inputArray: any) => {
  return inputArray.map((item: any) => ({
    value: item.category_id,
    title: item.category_name,
    children:
      item.children.length > 0 ? transformArrayIntoTreeFav(item.children) : [],
  }));
};

function getResourceTypeFromUrl(): string | null {
  // Get the current URL
  const url = window.location.href;

  // Extracting the query parameter from the URL
  const queryString = url.split("?")[1];
  if (!queryString) return null;

  // Parsing the query string into key-value pairs
  const params = new URLSearchParams(queryString);

  // Checking for each possible parameter type and returning the corresponding resource type
  if (params.has("ticketId")) {
    return "ticket";
  } else if (params.has("contentId")) {
    return "content";
  } else if (params.has("hdTicketId")) {
    return "hdTicket";
  } else if (params.has("hdContentId")) {
    return "hdContent";
  } else {
    return null;
  }
}

const returnPriority = (str: string): string => {
  let tolowerString = str?.toLowerCase();
  if (tolowerString === "u") {
    return "Urgent";
  } else if (tolowerString === "m") {
    return "Medium";
  } else if (tolowerString === "h") {
    return "High";
  } else {
    return "Standard";
  }
};

const getParamValue = (paramName: string): string | null => {
  // Get the current URL
  const url = window.location.href;

  // Create a URL object
  const urlObj = new URL(url);

  // Get the search params from the URL object
  const searchParams = urlObj.searchParams;

  // Get the value of the parameter
  const paramValue = searchParams.get(paramName);

  return paramValue;
};

const useIdAndObjectType = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const findCurrentLocation = () => {
    function isAttributePresent(attributeName: any) {
      return searchParams.has(attributeName);
    }
    if (isAttributePresent("contentId") || isAttributePresent("hdContentId")) {
      return "content";
    } else if (
      isAttributePresent("ticketId") ||
      isAttributePresent("hdTicketId")
    ) {
      return "ticket";
    }
  };

  const idMappings = {
    ticketId: "ticket/detail/",
    hdTicketId: "ticket/detail/",
    contentId: "content/",
    hdContentId: "content/",
  };

  const idType = Object.keys(idMappings).find(
    (type) => !_.isNull(searchParams.get(type))
  );

  if (idType) {
    const id = searchParams.get(idType);

    let objectType = findCurrentLocation() === "ticket" ? "T" : "C";
    let typeName = findCurrentLocation();

    return {
      id,
      objectType,
      idType,
      typeName,
      isHd: idType === "ticketId" || idType === "contentId" ? false : true,
    };
  } else {
    return {
      id: null,
      objectType: null,
      idType: null,
      typeName: null,
      isHd: null,
    };
  }
};
const handleUpload = (id, objectType, uploadedFiles) => {
  let filterData = uploadedFiles.filter((obj) =>
    obj.hasOwnProperty("originFileObj")
  );

  filterData.forEach((file) => {
    const formData = new FormData();
    formData.append("files", file.originFileObj);

    fetch(
      `${localStorage.getItem("reqUrl")}/api/attachment/${id}/${objectType}`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => {
        if (response.ok) {
          // Handle successful upload
          message.success(`${file.name} uploaded successfully`);
        } else {
          // Handle upload error
          message.error(`${file.name} upload failed`);
        }
      })
      .catch((error) => {
        // Handle network error
        console.error("Error uploading file:", error);
        message.error(`${file.name} upload failed: ${error.message}`);
      });
  });
};

const clearLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
};

const getBaseUrl = () => {
  const urlObj = new URL(window.location);

  if (!localStorage.getItem("reqUrl")) {
    localStorage.setItem("reqUrl", `${urlObj.protocol}//${urlObj.hostname}`);
  }
  return true;
};

const useUserData = () => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const parsedUserData = userData ? JSON.parse(userData) : {};
    setUserData(parsedUserData);
  }, []);

  return userData;
};

const disableTabKey = () => {
  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  };

  window.addEventListener("keydown", handleKeyDown);

  // Return a function to remove the event listener
  return () => {
    window.removeEventListener("keydown", handleKeyDown);
  };
};

// Function to enable the Tab key (remove the listener)
const enableTabKey = (cleanupFunction) => {
  if (cleanupFunction) {
    cleanupFunction();
  }
};

const returnStringSource = (type, isHd) => {
  switch (`${isHd}-${type}`) {
    case "true-content":
      return "HDCONTENT";
    case "false-content":
      return "MYCONTENT";
    case "true-ticket":
      return "HDTICKET";
    case "false-ticket":
      return "MYTICKET";
    default:
      return null;
  }
};

const removeLocalStorageItem = (arrayOfKeys) => {
  if (arrayOfKeys.length > 0) {
    arrayOfKeys.map((a) => localStorage.removeItem(a));
  }
};

const useZoomLevel = () => {
  const [zoomLevel, setZoomLevel] = useState(window.devicePixelRatio);

  useEffect(() => {
    const handleZoomChange = () => {
      setZoomLevel(window.devicePixelRatio);
    };

    window.addEventListener("resize", handleZoomChange);

    return () => {
      window.removeEventListener("resize", handleZoomChange);
    };
  }, []);

  return zoomLevel * 100;
};
const containsText = (array, text) => {
  return array.includes(text) ? { "pointer-events": "none" } : {};
};

const preLoginCall = () => {};

const redirectEditSearch = (value, isTicket) => {
  if (isTicket === "T") {
    window.open(`hd_ticket/details?hdTicketId=${value}`, "_blank");
  } else if (isTicket === "C") {
    window.open(`content/my_content/details?hdContentId=${value}`, "_blank");
  }
};

const redirectEdit = (value, isTicket) => {
  if (isTicket === "T") {
    window.open(`/hd_ticket/details?hdTicketId=${value}`, "_blank");
  } else if (isTicket === "C") {
    window.open(`/content/my_content/details?hdContentId=${value}`, "_blank");
  }
};

const localItemsPerPage = (setFunction, defaultValue, key) => {
  const userData = localStorage.getItem("userData");
  const parsedUserData = userData ? JSON.parse(userData) : {};
  const { items_per_page } = parsedUserData;
  let perPage = items_per_page;

  if (perPage) {
    setFunction(perPage);
  } else {
    setFunction(defaultValue);
  }
};

const formatDate = (inputDate) => {
  // Create a new Date object from the input string
  const date = new Date(inputDate);

  // Get the day, month, and year
  const day = String(date.getUTCDate()).padStart(2, "0"); // Zero pad day
  const month = date.toLocaleString("default", { month: "short" }); // Get month name
  const year = date.getUTCFullYear(); // Get full year

  // Format and return the date
  return `${day} ${month} ${year}`;
};

const isDateLower = (date) => {
  const givenDate = new Date(date);
  const currentDate = new Date();

  // Compare the two dates
  return givenDate < currentDate;
};

const removeSecondsFromDate = (dateStr) => {
  // Create a new Date object from the input date string
  const date = new Date(dateStr);

  // Extract year, month, day, hour, and minute
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Format to "YYYY-MM-DD HH:MM"
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

const updateClickedTaxonomy = (
  tabBody,
  clickedTaxonomy,
  setClickedTaxonomy,
  xyzVar
) => {
  if (!Array.isArray(tabBody) || !Array.isArray(clickedTaxonomy)) {
    throw new Error("tabBody and clickedTaxonomy should be arrays");
  }

  // If clickedTaxonomy is empty, add xyzVar directly
  if (clickedTaxonomy.length === 0) {
    setClickedTaxonomy([xyzVar]);
    return;
  }

  // Find the subarray index and the ID in tabBody
  let foundIndex = -1;
  for (let i = 0; i < tabBody.length; i++) {
    if (tabBody[i].some((item) => item.id === xyzVar)) {
      foundIndex = i;
      break;
    }
  }

  // If xyzVar is found in tabBody
  if (foundIndex !== -1) {
    const updatedTaxonomy = [...clickedTaxonomy];
    updatedTaxonomy[foundIndex] = xyzVar; // Replace or add the value at the found index

    // Remove all elements after the found index
    const trimmedTaxonomy = updatedTaxonomy.slice(0, foundIndex + 1);

    setClickedTaxonomy(trimmedTaxonomy);
  }
};

const getItemsPerPage = (objectName, defaultValue = 10) => {
  const storedObject = localStorage.getItem(objectName);

  if (!storedObject) {
    console.log(`Object "${objectName}" not found in local storage`);
    return defaultValue;
  }

  try {
    const parsedObject = JSON.parse(storedObject);

    if (!Object.prototype.hasOwnProperty.call(parsedObject, "items_per_page")) {
      console.log(
        `Property "items_per_page" not found in object "${objectName}"`
      );
      return defaultValue;
    }

    return parsedObject.items_per_page;
  } catch (error) {
    console.error(`Error parsing object "${objectName}":`, error);
    return defaultValue;
  }
};

const preLoginAPICall = () => {
  fetch(`${localStorage.getItem("reqUrl")}/api/public/pre-login`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === "success") {
        window.location.href = data.data["redirect-url"];
      } else if (data.status === "unauthorized") {
        window.location.href = data.data["redirect-url"];
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export {
  preLoginAPICall,
  getItemsPerPage,
  updateClickedTaxonomy,
  removeSecondsFromDate,
  isDateLower,
  formatDate,
  localItemsPerPage,
  redirectEdit,
  preLoginCall,
  containsText,
  transformArrayIntoTreeFav,
  useZoomLevel,
  removeLocalStorageItem,
  returnStringSource,
  disableTabKey,
  enableTabKey,
  useUserData,
  getBaseUrl,
  clearLocalStorage,
  useIdAndObjectType,
  getParamValue,
  downloadFile,
  TruncateText,
  transformArrayIntoTree,
  getResourceTypeFromUrl,
  returnPriority,
  handleUpload,
  redirectEditSearch,
};
